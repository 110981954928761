@import '../../styles/variables';
.team {
  padding: 100px 0;
  background-color: #000000;
  position: relative;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 9;
  }
  h2 {
    color: $white;
    margin-bottom: 2em;
    text-align: center;
  }
  .team-container {
    border: 1px solid $white;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    text-align: center;
    img {
      width: 80%;
      margin-bottom: 2em;
    }
  }
}

@media (max-width: $hsm-breakpoint) {
  .team {
    .team-container {
      flex-direction: column;
    }
  }
}
