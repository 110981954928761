@import '../../styles/variables';
.story {
  padding: 5em 0;
  h2 {
    color: $green;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 1.5em;
  }
  img {
    width: 60%;
    border-radius: 50%;
  }
  ul {
    margin: 0;
    li {
      margin-bottom: 1em;
      ol {
        list-style: none;
        margin-top: 0.7em;
        li {
          position: relative;
          color: #cccccc;
          &::before {
            content: url('../../assets/arrow-right.svg');
            display: block;
            height: 25px;
            width: 25px;
            position: absolute;
            top: 0;
            left: -35px;
          }
        }
      }
    }
  }
}
