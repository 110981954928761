@import '../../styles/variables';
@import '../../styles/mixins';
.navbar {
  background: $dark;
  padding: 1em 0;
  .nav-link {
    color: $white !important;
    cursor: pointer;
  }
  button {
    .dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $danger;
      margin-right: 0.6em;
    }
  }
}
.modal-container {
  min-height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: transparent;
  backdrop-filter: blur(3px);
  z-index: 99;
  .custom-modal {
    position: relative;
    padding: 0.2em;
    margin: 100px auto auto auto;
    max-width: 400px;
    @include gradientAnimation;
    .cancel {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
    .content {
      position: relative;
      width: 100%;
      background-color: $dark;
      z-index: 9999;
      padding: 2em 1em;
      a,
      h4 {
        font-family: 'Space Grotesk', sans-serif;
      }
      a {
        color: #fff;
        margin-top: 100px auto auto auto;
        text-align: center;
      }
      h4 {
        text-align: center;
        margin-bottom: 2em;
      }
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      button {
        box-shadow: none;
        background: rgba(113, 239, 165, 0.3);
        border-radius: 8px;
        display: block;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #71efa5;
        font-weight: bold;
        margin: 0 auto 1em;
        width: 352px;
        height: 88px;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-container {
    .custom-modal {
      width: 85%;
      margin: 100px auto;
      .content {
        button {
          width: 90%;
        }
      }
    }
  }
}
