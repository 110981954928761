@import '../../styles/variables';
@import '../../styles/mixins';
.hero {
  background-image: url('../../assets/hero-bg.png');
  padding: 5em 0;
  .content {
    h1 {
      color: $green;
      font-weight: 600;
    }
    p {
      color: $white;
      margin: 1em 0 2em 0;
    }
    button {
      margin-right: 1em;
      margin-bottom: 1em;
      img {
        max-width: 25px;
      }
    }
  }
  figure {
    text-align: center;
    img {
      width: 60%;
      border-radius: 25px;
    }
  }
}

@media (max-width: $sm-breakpoint) {
  .hero {
    padding-bottom: 1em;
    .content {
      margin-bottom: 3em;
    }
    figure {
      img {
        width: 100%;
      }
    }
  }
}
