@mixin align-center {
  display: inline-flex;
  align-items: center;
}
@mixin justify-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@mixin gradientAnimation {
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    animation: moveGradient 4s alternate infinite;
  }
}
@mixin styled-scrollbar {
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #525252;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #6d6d6d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #979797;
  }
}
