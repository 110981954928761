@import '../../styles/variables';
.lunch {
  padding: 3em 0;
  text-align: center;
  h2 {
    color: $green;
    margin-bottom: 2em;
  }
  p {
    font-size: 2em;
  }
}
