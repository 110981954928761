@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/mixins';

.mint {
  background-color: #000000;
  padding: 120px 0;
  position: relative;
  overflow: hidden;
  .container {
    position: relative;
    z-index: 9;
  }
  h2 {
    text-align: center;
    color: $white;
    margin-bottom: 2em;
  }
  .counter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 300px;
    margin: auto;

    button {
      box-shadow: none;
      background-color: transparent;
      &:active {
        transform: none;
      }
    }

    .counter {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 200px;
      border: 2px solid $white;
      padding: 0.4em;
      border-radius: 5px;
      color: $white;
    }

    .total {
      padding: 1em;
      margin: 2em auto 2em auto;
    }

    .gradient-container {
      position: relative;
      @include gradientAnimation;
      color: $white;
      padding: 0.2em;
      border-radius: 12px;
      button {
        position: relative;
        background-color: $dark;
        box-shadow: none;
        z-index: 99;
      }
    }
  }

  .cta,
  .max {
    margin-bottom: 1em;
    button {
      width: 100%;
      background-color: #000 !important;
      padding: 1em;
    }
  }

  button.verify {
    background: $green;
    padding: 1em;
    color: $gray;
    font-weight: bold;
    text-transform: uppercase;
  }

  .progress {
    position: relative;
    margin: 5em auto 0 auto;
    max-width: 800px;
    background: $white;
    height: 40px;
    border-radius: 11px;
    .bar {
      background: linear-gradient(
        93.01deg,
        #71efa5 2.48%,
        #0334dc 59.29%,
        #dcba75 99.33%,
        #9a0b15 155.01%,
        #fced22 194.11%,
        #fc6ef6 235.2%,
        #ed6e42 284.2%
      );
      &.minted {
        background: #70efa5;
      }
    }
    h5 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $dark;
    }
  }
}

@media (max-width: 768px) {
  .modal-container {
    .custom-modal {
      width: 85%;
      margin: 100px auto;
      .content {
        button {
          width: 90%;
        }
      }
    }
  }
  .mint {
    .img-container {
      img {
        width: 100%;
      }
    }
    .counter-container {
      width: 100%;
      margin-top: 3em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2em;
    }
    .mint-message {
      width: 100%;
      text-align: center;
      font-size: 1.2em;
    }
    .progress {
      margin-top: 30px;
    }
  }
}
