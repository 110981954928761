@import '../../styles/variables';
@import '../../styles/mixins';
.roadmap {
  padding: 5em 0;
  button {
    background-color: $green;
  }
  h2 {
    color: $green;
    margin-bottom: 3em;
    text-align: center;
  }
  .roadmap-card {
    position: relative;
    height: 100%;
    background-color: $green;
    padding: 0.2em;
    text-align: center;
    .content {
      position: relative;
      background-color: $dark;
      padding: 1em;
      z-index: 99;
      .top {
        background-color: #000000;
        padding: 1em;
        margin-bottom: 2em;
        h3 {
          color: $green;
        }
        img {
          opacity: 0;
          margin: auto;
          margin-top: 1em;
        }
      }
      p {
        margin-bottom: 0;
        min-height: 150px;
      }
    }
    &.completed {
      @include gradientAnimation;
      .content {
        .top {
          h3 {
            text-decoration: line-through;
          }
          img {
            opacity: 1;
          }
        }
      }
    }
  }
}
