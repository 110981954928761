@import '../../styles/variables';
.footer {
  padding: 1em 0;
  button {
    margin: 0 1em;
    img {
      max-width: 25px;
    }
  }
}
