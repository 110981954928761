@import '../../styles/variables';
@import '../../styles/mixins';
.rarity {
  position: relative;
  padding: 3em 0;
  h2 {
    text-align: center;
    margin: 2em 0;
  }
  .top-filter,
  .bottom-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    margin-bottom: 4em;
    flex-wrap: wrap;
    li {
      margin-right: 2em;
      margin-bottom: 2em;
    }
    button {
      text-transform: capitalize;
      &.active {
        background-color: $green;
        color: $dark;
      }
    }
  }
  .bottom-filter {
    margin-top: 3em;
    justify-content: center;
    li {
      margin: 0 1em;
    }
    button {
      @include align-center;
      img {
        margin-right: 0.5em;
      }
    }
  }
  .filtered-items {
    @include styled-scrollbar;
    overflow: auto;
    padding-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .rarity-card-container {
      position: relative;
      @include gradientAnimation;
      padding: 0.2em;
      margin-right: 2em;

      .rarity-card {
        width: 200px;
        background-color: $dark;
        position: relative;
        border: 1px solid gray;
        padding: 1em;
        text-align: center;
        z-index: 9;
        img {
          width: 90%;
          margin-bottom: 2em;
        }
        h4,
        h5 {
          font-family: 'Space Grotesk', sans-serif;
          text-transform: capitalize;
        }
        h4 {
          font-size: 1.2em;
        }
        h5 {
          color: $green;
          margin-bottom: 0.8em;
        }
      }
    }
  }
  .rarity-no-data {
    min-height: 300px;
    width: 100%;
    @include justify-center;
  }
}

@media (max-width: $sm-breakpoint) {
  .rarity {
    .top-filter,
    .bottom-filter {
      justify-content: center;
      li {
        margin: 0 1em 2em 0;
      }
      button {
        min-width: 130px;
        font-size: 0.9em;
      }
    }
  }
}
